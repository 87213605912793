<template>
  <b-card no-body class="mb-0">
    <b-card-body class="pt-0">
      <b-row>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:statusFilter', val)"
            placeholder="Select Status.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Created by</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="createdByFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:createdByFilter', val)"
            placeholder="Select Created By.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Interview by</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="interviewByFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:interviewByFilter', val)"
            placeholder="Select Interview By.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Interview Filter By Date</label>
          <flat-pickr
            :value="interviewDateFilter"
            @input="(val) => $emit('update:interviewDateFilter', val)"
            :config="{
              dateFormat: 'Y-m-d',
            }"
            class="form-control"
            placeholder="Pick Date.."
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    flatPickr,
  },
  props: {
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    createdByFilter: {},
    recruiterByFilter: {},
    interviewByFilter: {},
    interviewDateFilter: {},
    employeeOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
